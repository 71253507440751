import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  datawithverify: any;
  jsondata: any;

  constructor(private http: HttpClient) {
    //sessionStorage.setItem('loginToken', '9b0aa16f6788840981ead04acaa6abf6');
    //sessionStorage.setItem('userID', '1');
  }

  dataConverter(data){
    this.datawithverify = data;
    this.datawithverify.userID = sessionStorage.getItem('userID');
    this.datawithverify.loginToken = sessionStorage.getItem('loginToken');
    this.jsondata = JSON.stringify(this.datawithverify);
  }

  getAuth(data){
    this.jsondata = JSON.stringify(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/auth.php?func=login', this.jsondata);
  }

  //Ueberpruefung ob Online
  getOnline() {
    return this.http.get('https://systems.aljoma.at/databaseAPI/notfallpaket/auth.php?func=online');
  }

  //Lager
  getProductsByUserID(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataSelect.php?func=getProductsByID', this.jsondata);
  }

  //Home
  getProductsAblaufdatum(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataSelect.php?func=getProductsAblaufdatum', this.jsondata);
  }

  //Produkt bearbeiten
  getProductByID(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataSelect.php?func=getProductByID', this.jsondata);
  }

  //Produkt löschen
  deleteProductByID(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataDo.php?func=deleteProductByID', this.jsondata);
  }

  //Produkt hinzufügen
  insertProduct(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataInsert.php?func=insertProduct', this.jsondata);
  }

  //Produkt Bearbeiten
  updateProductByID(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataDo.php?func=updateProductByID', this.jsondata);
  }

  //Login Verlauf
  getLoginVerlauf(data){
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataSelect.php?func=getLoginVerlauf', this.jsondata);
  }

  //getUserData
  getUserData(data) {
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataSelect.php?func=getUserData', this.jsondata);
  }

  //updateUserData
  updateUserData(data) {
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataDo.php?func=updateUserData', this.jsondata);
  }

  //updatePasswort
  updatePasswort(data) {
    this.dataConverter(data);
    return this.http.post('https://systems.aljoma.at/databaseAPI/notfallpaket/xdataDo.php?func=updatePasswort', this.jsondata);
  }
}
