import { Component, OnInit } from '@angular/core';
import { isPlatform, MenuController } from '@ionic/angular';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ApiService } from './api.service';
import {Md5} from 'ts-md5/dist/md5';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Lager', url: '/lager', icon: 'cube' },
    { title: 'Einstellungen', url: '/einstellungen', icon: 'settings' },
  ];

  public isIos = false;

  name: string;
  benutzername: string;
  avatarurl: string;

  constructor(private router: Router, private menu: MenuController, private api: ApiService) {
    //Um SideMenu auf bestimmten Seiten auszublenden
    router.events.subscribe((event: RouterEvent) => {
      if(event instanceof NavigationEnd) {
        const path = window.location.pathname;
        if(path!=='/loader' && path!=='/login' && path!=='/slides' && path!=='/') {
          this.menu.enable(true);
          this.loadMenu();
        }
        else { this.menu.enable(false); }
        this.checkOnline();
      }
    });
  }

  ngOnInit() {
    if (isPlatform('hybrid')) {
      this.isIos = true;
    }
  }

  loadMenu() {
    this.name = sessionStorage.getItem('vorname') + ' ' + sessionStorage.getItem('zuname');
    this.benutzername = sessionStorage.getItem('benutzername');
    this.avatarurl = 'https://api.dicebear.com/7.x/bottts/svg?seed=' + Md5.hashStr(sessionStorage.getItem('benutzername'));
  }

  checkOnline() {
    this.api.getOnline().subscribe((res)=>{},
    (err)=> {
      alert('Es konnte keine Verbindung zum Server hergestellt werden! 🙁\nVersuchen Sie es später erneut!');
    });
  }
}
