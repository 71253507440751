import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loginToken: any;
  userID: any;
  ltexpire: any;
  datum: any;

  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //Auf Login überprüfen
    this.loginToken = sessionStorage.getItem('loginToken');
    this.userID = sessionStorage.getItem('userID');
    this.ltexpire = new Date(sessionStorage.getItem('ltexpire'));
    this.datum = new Date();

    if(this.loginToken!=='' && this.userID!=='' && this.ltexpire<this.datum){
      this.router.navigate(['/']);
    }
    return true;
  }
}
