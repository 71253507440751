<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu disabled contentId="main-content" menuId="basemenu" type="overlay" ><!--Um SideMenu auszublenden-->
      <ion-content>
        <ion-list id="inbox-list">

          <!--Avatar Profile-->
          <div *ngIf="isIos"><br><br></div>
          <ion-item lines="none">
            <ion-avatar id="avatar" slot="start">
              <!-- <img src="{{avatarurl}}" /> -->
              <img [src]="avatarurl" />
            </ion-avatar>
            <ion-label>
              <h2 id="nametag">{{name}}</h2>
              <p>{{benutzername}}</p>
            </ion-label>
          </ion-item>
          <br>

          <!--SideMenu-->
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
<!--*ngIf="loader!=='/loader' && loader!=='/' && loader!=='/login' && loader!=='/slides'"-->
